<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <span class="heading">Excel Report</span>
          </v-flex>
          <v-flex xs12 pt-3>
            <v-layout wrap justify-start>
              <v-flex xs12 lg4>
                <v-select
                  outlined
                  hide-details
                  dense
                  :items="reportTypes"
                  v-model="report"
                  placeholder="Select Report Type"
                >
                </v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout
          wrap
          justify-start
          pa-5
          v-if="report === 'Snakewise Rescue Count'"
        >
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 md3 sm3 lg3>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- <span>From Date</span> -->
                    <v-text-field
                      v-model="fromdate"
                      label="From Date"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      hide-details="true"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="alldata"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromdate"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substring(0, 10)
                    "
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md3 sm3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <span>To Date</span> -->
                        <v-text-field
                          v-model="todate"
                          label="To Date"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="alldata"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        :active-picker.sync="activePickerto"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substring(0, 10)
                        "
                        min="1950-01-01"
                        @change="saveto"
                      ></v-date-picker>
                    </v-menu>
              </v-flex>
              <v-flex xs12 md3 sm3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-select
                  outlined
                  hide-details
                  dense
                  placeholder="Select District"
                  :items="districts"
                  v-model="district"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>
                <v-btn
                  color="#fdac53"
                  class="downlink"
                  target="_blank"
                  @click="snakewisegetExcel()"
                >
                  <span
                    style="
                      font-size: 12px;
                      font-family: poppinssemibold;
                      color: black;
                    "
                  >
                    Excel<v-icon color="black"
                      >mdi-microsoft-excel</v-icon
                    ></span
                  >
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout
          wrap
          justify-start
          pa-5
          v-if="report === 'Rescuer Training List'"
        >
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 md3 sm3 lg3>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- <span>From Date</span> -->
                    <v-text-field
                      v-model="fromdate"
                      label="From Date"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      hide-details="true"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="alldata"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromdate"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substring(0, 10)
                    "
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md3 sm3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <span>To Date</span> -->
                        <v-text-field
                          v-model="todate"
                          label="To Date"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="alldata"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        :active-picker.sync="activePickerto"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substring(0, 10)
                        "
                        min="1950-01-01"
                        @change="saveto"
                      ></v-date-picker>
                    </v-menu>
              </v-flex>
              <v-flex xs12 md3 sm3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-select
                  outlined
                  hide-details
                  dense
                  placeholder="Select District"
                  :items="districts"
                  v-model="district"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>
                <v-btn
                  color="#fdac53"
                  class="downlink"
                  target="_blank"
                  @click="rescuertraininggetExcel()"
                >
                  <span
                    style="
                      font-size: 12px;
                      font-family: poppinssemibold;
                      color: black;
                    "
                  >
                    Excel<v-icon color="black"
                      >mdi-microsoft-excel</v-icon
                    ></span
                  >
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout
          wrap
          justify-start
          pa-5
          v-if="report === 'Rescuer List'"
        >
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 md3 sm3 lg3>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- <span>From Date</span> -->
                    <v-text-field
                      v-model="fromdate"
                      label="From Date"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      hide-details="true"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="alldata"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromdate"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substring(0, 10)
                    "
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md3 sm3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <span>To Date</span> -->
                        <v-text-field
                          v-model="todate"
                          label="To Date"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="alldata"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        :active-picker.sync="activePickerto"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substring(0, 10)
                        "
                        min="1950-01-01"
                        @change="saveto"
                      ></v-date-picker>
                    </v-menu>
              </v-flex>
              <v-flex xs12 md3 sm3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-select
                  outlined
                  hide-details
                  dense
                  placeholder="Select District"
                  :items="districts"
                  v-model="district"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>
                <v-btn
                  color="#fdac53"
                  class="downlink"
                  target="_blank"
                  @click="rescuerlistgetExcel()"
                >
                  <span
                    style="
                      font-size: 12px;
                      font-family: poppinssemibold;
                      color: black;
                    "
                  >
                    Excel<v-icon color="black"
                      >mdi-microsoft-excel</v-icon
                    ></span
                  >
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout
          wrap
          justify-start
          pa-5
          v-if="report === 'Top Rescuers List'"
        >
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 md3 sm3 lg3>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- <span>From Date</span> -->
                    <v-text-field
                      v-model="fromdate"
                      label="From Date"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      hide-details="true"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="alldata"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromdate"
                    :active-picker.sync="activePicker"
                    :max="
                      new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substring(0, 10)
                    "
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 md3 sm3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <span>To Date</span> -->
                        <v-text-field
                          v-model="todate"
                          label="To Date"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="alldata"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        :active-picker.sync="activePickerto"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substring(0, 10)
                        "
                        min="1950-01-01"
                        @change="saveto"
                      ></v-date-picker>
                    </v-menu>
              </v-flex>
              <v-flex xs12 md3 sm3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                <v-select
                  outlined
                  hide-details
                  dense
                  placeholder="Select District"
                  :items="districts"
                  v-model="district"
                >
                </v-select>
              </v-flex>
              <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>
                <v-btn
                  color="#fdac53"
                  class="downlink"
                  target="_blank"
                  @click="toprescuerslistgetExcel()"
                >
                  <span
                    style="
                      font-size: 12px;
                      font-family: poppinssemibold;
                      color: black;
                    "
                  >
                    Excel<v-icon color="black"
                      >mdi-microsoft-excel</v-icon
                    ></span
                  >
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
          <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      districts: [
        "Palakkad",
        "Alappuzha",
        "Malappuram",
        "Kannur",
        "Wayanadu",
        "Kozhikode",
        "Thrissur",
        "Thiruvananthapuram",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Kollam",
        "Pathanamthitta",
        "Kasaragod",
      ],
      reportTypes: [
        "Snakewise Rescue Count",
        "Top Rescuers List",
        "Reported & Rescued Snakes(Yearwise)",
        "Districtwise Top Rescued",
        "Rescuer Training List",
        "Rescuer List",
      ],
      report: "",
      district: "",
      yearData: [
        "2024",
        "2023",
        "2022",
        "2021",
        "2020",
        "2019",
        "2018",
        "2017",
      ],
      activePicker: null,
      fromdate: null,
      menu: false,
      activePickerto: null,
      todate: null,
      menu2: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.activePickerto = "YEAR"));
    },
  },
  methods: {
    snakewisegetExcel() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/operation/rescued/district/snakewise/count/download/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        responseType: "blob",
        params: {
          district: this.district,
          from:this.fromdate,
          to:this.todate
        },
      })
        .then((response) => {
          this.appLoading = false;
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Snakewise Rescue Count.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    rescuertraininggetExcel() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/traininglist/download/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        responseType: "blob",
        params: {
          district: this.district,
          from:this.fromdate,
          to:this.todate
        },
      })
        .then((response) => {
          this.appLoading = false;
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "RescuerTrainingList.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    rescuerlistgetExcel() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/getlist/download/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        responseType: "blob",
        params: {
          district: this.district,
          from:this.fromdate,
          to:this.todate
        },
      })
        .then((response) => {
          this.appLoading = false;
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "RescuerList.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    toprescuerslistgetExcel() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/toprescuers",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        responseType: "blob",
        params: {
          district: this.district,
          from:this.fromdate,
          to:this.todate
        },
      })
        .then((response) => {
          this.appLoading = false;
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "TopRescuers.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    save(fromdate) {
      this.$refs.menu.save(fromdate);
    },
    saveto(todate) {
      this.$refs.menu2.save(todate);
    },
  },
};
</script>
<style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
.tableheading {
  font-family: poppinsregular;
  font-size: 15px !important;
  color: black !important;
  text-transform: uppercase !important;
}
.tablesubheading {
  font-family: poppinsregular;
  font-size: 10px;
}
</style>